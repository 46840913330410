<template>


  <v-dialog v-model="display"
            fullscreen>
    <v-sheet dark  class="fill-height d-flex flex-column">
      <div class="d-flex justify-space-between py-2 px-3 align-center">
        <h6>{{ file.name }}</h6>
        <div>
          <v-btn v-if="!SM" small :href="url" class="noCaps mr-3"  outlined download type="attachment" target="_self">
            <v-icon small>mdi-download</v-icon>
            {{$t('Download')}}
          </v-btn>
          <v-btn :small="SM" icon @click="display = false"><v-icon :small="SM" >mdi-close</v-icon></v-btn>
        </div>
      </div>
      <v-btn v-if="SM" block small :href="url" class="noCaps mb-3"  text download type="attachment" target="_self">
        <v-icon small>mdi-download</v-icon>
        {{$t('Download')}}
      </v-btn>

      <iframe v-if="url && !SM" frameBorder="0"  class="pdfStyle flex-grow-1" width="100%" :height="`100%`" :src="pdfUrl"></iframe>
      <div v-else-if="url && SM" class="flex-grow-1">
        <pdf
            v-if="!pagesCount" :page="1"
            key="first"
            :src="pdfData"
            @num-pages="pagesCount = $event"
        />
        <template v-if="pagesCount && url">
          <pdf v-for="page in pagesCount" :key="page"  :src="pdfData" :page="page" />
        </template>
      </div>

    </v-sheet>
  </v-dialog>

</template>

<script>
import {mapActions} from "vuex";
import pdf from "vue-pdf";


export default {
  name: "wsVideo",
  components : {
    pdf
  },
  props : {
    value : {
      type : Boolean,
      default : false
    },
    file : {
      type : Object,
      default() { return {} }
    },
    src : {
      type : String,
      default :''
    },
    courseId : {
      type : String
    },
    student : {
      type : Boolean
    },
    testId : {
      type : String,
    },
    fullScreen : {
      type : Boolean,
      default : false
    },
    isPublic : {
      type : Boolean,
      default : false,
    },
    height : {
      type : Number,
      default : 350
    },
    isCollapsed : {
      type : Boolean,
      default : false
    }
  },
  data() {
    return {
      display : false,
      uuid : '',
      url : null,
      pagesCount : null,
      pdfData : null
    }
  },
  computed : {
    pdfUrl() {
      if ( !this.url ) {
        return null
      }
      return this.url + '&noforce=true'
    }
  },
  watch: {
    file() {
      this.initFile()
    },
    SM() {
      if ( this.SM && this.url) {
        this.loadPdfData()
      }
    },
    display() {
      if ( this.display !== this.value ) {
        this.$emit('input' , this.display)
      }
    },
    value() {
      if ( this.display !== this.value ) {
        this.display = this.value
      }
    },
  },
  methods : {
    ...mapActions('upload', [ 'GET_PRIVATE_FILE', 'GET_PUBLIC_FILE', 'GET_STUDENT_COMPONENT_FILE', 'GET_STUDENT_TEST_FILE' ]),

    async loadPdfData() {
      const response = await fetch(this.pdfUrl, { responseType: 'arraybuffer' });
      const pdfData = await response.arrayBuffer();
      this.pdfData = new Uint8Array(pdfData)
    },

    async getFile( ) {

      if ( this.student ) {
        return this.openStudentFile(this.file)
      }

      let result = this.isPublic ? await this.GET_PUBLIC_FILE(this.file.uuid) :  await this.GET_PRIVATE_FILE(this.file.uuid)
      if (!result) { return this.notify(this.$t('FileNotfound')) }

      this.url = result.url

      if ( this.SM) {
        this.loadPdfData()
      }
      
    },

    async openStudentFile(file) {


      this.url = null

      let data =  null
      let result = null;
      if ( this.testId ) {
        data = {
          file : file.uuid,
          test : this.testId
        }
        result = await this.GET_STUDENT_TEST_FILE(data)
      } else {
        data = {
          file : file.uuid,
          course : this.courseId
        }
        result = await this.GET_STUDENT_COMPONENT_FILE(data)
      }

      if (!result) { return this.notify(this.$t('FileNotfound')) }

      this.url = result.url

      if ( this.SM) {
        this.loadPdfData()
      }
      
    },

    initFile() {
      if (this.file.uuid) {
        this.getFile()
      }
    }

  },
  beforeMount() {
    this.url = null
  },
  mounted() {
    this.display = this.value
    this.initFile()
  }
}
</script>

<style scoped>
.pdfStyle {
  border: none;
  padding: 0 !important;
  background-color: white;
}
</style>